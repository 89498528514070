.Section {
  background-color: #EEEEEE;
}

img {
  margin-top: 1vh;
  height: 45vw;
  width: 45vw;
}

.cv {
  border-radius: 50%;
  margin-top: -3vh;
  aspect-ratio: 1/1;
  margin-left: 0;
}

.container {
  height: 150vh;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
}

.left {
  width: 100%;
  border-right: solid #D65A31;
  padding-left: 10vw;
}

.center {
  width: 100%;
  padding: 10vh;
  padding-top: 5vh;
}

.right {
  width: 100%;
  border-left: solid #D65A31;
  padding-left: 10vh;
}

.right ul {
  width: 60%;
  margin: 0 auto;
}

h2 {
  margin-top: 3vh;
  width: 60%;
  font-size: min(18px, 3vw);
}

.t1, .t2 {
  font-size: max(min(15px, 3vw), 14px);
}

.t3 {
  font-size: max(min(15px, 2vw), 14);
}

.hab, .hab1 {
  line-height: 1.8;
  font-size: max(min(2vw, 18px), 14);
}

.h2 {
  margin-top: 10vh;
  margin-bottom: 3vh;
}

.h4 {
  margin-top: 3vh;
  margin-bottom: 1.5vh;
  font-size: max(min(18px,2.2vw),16);
}

.right ul {
  width: 60%;
}

.smallScreen{
  font-size: 3vw;
}