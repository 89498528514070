.Section {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, #98a6ad, #575f63);
  color: azure;
  line-height: 0.5em;
  padding: 3px;
  font-size: 5vw;
  padding-top: 10px;
}

.Section p {
  margin-top: 3vh;
  text-align: justify;
  font-size: max(min(3vw, 18px), 10px);
}

 li {
  margin-top: 3vh;
  width: 90%;
  list-style-type: circle;
  font-size: max(min(2.5vw, 16px), 10px);
}

.blog1 {
  text-align: right;
  float: left;
  width: 90%;
  right: 0;
  margin-left: 5vw;
}

.separador {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  border-left: 1px solid #D65A31;
}

.blogImg {
  margin: 10vh auto;
  height: 70vh;
  width: 75vw;
  max-width: 100%;
}


