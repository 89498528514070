.navLink{
    color: white;
    font-size: large;
    text-decoration: none;
    margin: 0dvh 1dvw;
    font-size: x-large;
}

.links{
    color: black;
    text-decoration: none;
    font-size: large;
}
.links:hover{
    color: #FF7315;
    font-weight: bold;
}

.links:active{
    color: black;
    font-weight: bolder;
}
