.opac {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: relative;
}

.heroimg {
  height: 40dvh;
  margin-top: 20dvh;
  margin-left: 40dvw;
  margin-bottom: 20dvh
}

.bg1 {
  background-color: #98a6ad;
  text-align: center;
}

.bg2 {
  background-color: #98a6ad;
  text-align: center;
}

.carou {
  margin: auto;
  height: 70dvh;
  width: 70dvw;
}