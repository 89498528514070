.button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: #ffffff;
    background-color: #4caf50;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 2dvh;
    width: 30%;
    padding: 6 10px;
  }
  
  .button:hover {
    background-color: #45a049;
  }
  
  .button:focus {
    outline: none;
  }
  
  .button:active {
    background-color: #3e8e41;
  }

  Form{
    width: 60%;
    max-width: 80%;
    padding: 0;
  }