
.Section {
    background-color: #98a6ad;
    color: #EEEEEE;
    text-align: center;
}

.paragh {
    padding-top: 10dvh;
    padding-bottom: 10dvh;
    text-align: center;
    width: 70%;
    margin: 0 auto;
}

.paragh p {
    font-size: min(18px,2.5vw);
    line-height: 2;

}