.Section {
    background-color: #EEEEEE;
}

img {
    margin-top: 1dvh;
    height: 30dvh;
    width: 30dvh;
}

.cv {
    margin-top: 1dvh;
    width: 80%;
    aspect-ratio: 1/1;
    margin-left: 0;
}

.container {
    height: 250dvh;
    width: 100dvw;
    display: flex;
}

.left {
    width: 20%;
    border-right: solid #D65A31;
    padding-left: 1%;
}

.center {
    width: 50%;
    padding: 10dvh;
    padding-top: 5dvh;
}

.right {
    width: 20%;
    border-left: solid #D65A31;
    padding-left: 10dvh;
}

.right ul {
    width: 1dv1;
}

h2 {
    margin-top: 3dvh;
    width: 60%;
    font-size: min(20px, 3vw);
}

h4{
    font-size: min(20px, 3vw);

}

.t1 {
    font-weight: bold;
}

.t1, .t2, .t3 {
    font-size: min(18px,2.2vw);
}



.hab {
    line-height: 1.8;
}

.hab1 {
    line-height: 1.8;
    font-size: min(18px,2vw);
}

.h2 {
    margin-top: 10dvh;
    margin-bottom: 3dvh;
}

.h4 {
    margin-top: 3dvh;
    margin-bottom: 1.5dvh;
    font-size: min(20px, 3vw);

}

.right ul {
    width: 60%;
}